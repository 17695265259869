.flip {
  li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &:first-child {
      z-index: 2;
    }

    span {
      display: block;
      height: 100%;
      perspective: 300px;
    }
  }
}

.flip-pane {
  div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .flip-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }
}

.flipclock-container {
  .before {
    z-index: 3 !important;

    .flip-up {
      z-index: 2;
      animation: flip-ani-down 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;

      .flip-shadow {
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        animation: show 0.5s linear both;
      }
    }

    .flip-down {
      .flip-shadow {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        animation: show 0.5s linear both;
      }
    }
  }

  .active {
    animation: flip-ani-index 0.5s 0.5s linear both;
    //z-index: 2 !important;

    .flip-down {
      z-index: 2 !important;
      animation: flip-ani-up 0.5s 0.5s linear both;

      .flip-shadow {
        //background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
        animation: hide 0.5s 0.3s linear both;
      }
    }

    .flip-up {
      .flip-shadow {
        //background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        animation: hide 0.5s 0.3s linear both;
      }
    }
  }
}

.flip-up {
  transform-origin: 50% 100%;
  backface-visibility: hidden;
  top: 0;

  .flip-text {
    top: 0;
  }
}

.flip-down {
  transform-origin: 50% 0%;
  backface-visibility: hidden;
  bottom: 0;

  .flip-text {
    bottom: 0;
  }
}

@keyframes flip-ani-index {
  0% {
    z-index: 2;
  }
  5% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}

@keyframes flip-ani-up {
  0% {
    transform: rotateX(90deg);
  }
  60% {
    transform: rotateX(0deg);
  }
  80% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes flip-ani-down {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
    filter: none;
  }
  99% {
    opacity: 1;
    filter: none;
  }
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: rotateX(-90deg);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    filter: none;
  }
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
